import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map, Observable } from 'rxjs';
import { ShoppingCartModel } from '../../models/shopppingCart.model';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  urlBase: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getShoppingCart(createdBy: string): Observable<ShoppingCartModel[]> {
    let url = `${this.urlBase}/shopping-cart-requests?created_by=${createdBy}`;
    return this.http.get<{ data: ShoppingCartModel[] }>(url).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  postShoppingCart(data: ShoppingCartModel): Observable<any> {
    let url = `${this.urlBase}shopping-cart-request`;
    return this.http.post(url, data);
  }
}
