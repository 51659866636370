import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { PedidoReferenciaComponent } from './pedido-referencia/pedido-referencia.component';
import { PedidoMasivoComponent } from './pedido-masivo/pedido-masivo.component';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { EditSolicitudComponent } from './edit-solicitud/edit-solicitud.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { deliveryWindowsResolver } from './resolvers/delivery-windows.resolver';
import { materialsResolver } from './resolvers/materials.resolver';
import { plantsResolver } from './resolvers/plants.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    component: HomeComponent,
    resolve: [deliveryWindowsResolver, materialsResolver],
  },
  {
    path: 'pedidos',
    component: PedidosComponent,
    resolve: [deliveryWindowsResolver, materialsResolver, plantsResolver],
  },
  { path: 'pedido-referencia', component: PedidoReferenciaComponent },
  { path: 'pedido-masivo', component: PedidoMasivoComponent },
  { path: 'solicitud', component: SolicitudComponent },
  {
    path: 'edit-solicitud/:id',
    component: PedidosComponent,
    resolve: [deliveryWindowsResolver, materialsResolver, plantsResolver],
  },
  {
    path: 'historial',
    component: HomeComponent,
    resolve: [deliveryWindowsResolver, materialsResolver],
  },
  { path: 'notificaciones', component: NotificacionesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
