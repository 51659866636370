<header>
  <app-header></app-header>
</header>
<section>
  <div class="container-fluid">
    <div [formGroup]="form" class="row">
      <!--INICIO DE COL-MD-6 -->
      <div class="col-md-6 mb-30">
        <div class="card">
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate id="FormLeft">
              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Deudor <span>*</span></label
                >
                <select
                  class="form-select"
                  id="iddebtor"
                  required
                  (change)="onChangeDebtor()"
                  formControlName="debtor"
                >
                  <option selected disabled value="default">
                    Seleccionar...
                  </option>
                  <option
                    *ngFor="let debtor of debtorList"
                    [value]="debtor.debtor"
                    [attr.data-debtor]="debtor.name"
                  >
                    {{ debtor.debtor }} - {{ debtor.name }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>
              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Solicitante <span>*</span></label
                >
                <select
                  class="form-select"
                  id="idSolicitante"
                  required
                  (change)="onChangeSolicitante()"
                  formControlName="petitionerId"
                >
                  <option selected disabled value="0">Seleccionar...</option>
                  <option
                    *ngFor="let petitioner of solicitante"
                    [value]="petitioner.petitionerId"
                    [attr.data-debtor]="petitioner.petitionerName"
                  >
                    {{ petitioner.petitionerId }} -
                    {{ petitioner.petitionerName }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>
              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Fecha de entrega <span>*</span></label
                >

                <input
                  formControlName="requestedDeliveryDate"
                  type="date"
                  id="fecha_entrega"
                  class="form-control"
                  placeholder="dd/mm/yyyy"
                  [min]="minDate"
                  [max]="maxDate"
                  (change)="selectDate($event)"
                />
              </div>

              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Ventana de entrega <span>*</span></label
                >
                <select
                  class="form-select"
                  id="ventana_entrega"
                  required
                  formControlName="requestedDeliveryTimeWindow"
                >
                  <option selected disabled value="">Seleccionar...</option>
                  <option *ngFor="let i of rangoHorario" [value]="i.windowId">
                    {{ i.description }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>

              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Planta <span>*</span></label
                >
                <!--TODO solo se esta pasando el valor al form control cuando se selecciona 
                y no cuando se autoselecciona porcambio de solicitante -->
                <select
                  class="form-select"
                  id="plantaId"
                  required
                  formControlName="plantId"
                  (change)="onChangePlanta()"
                >
                  <option
                    *ngIf="planta.length > 1 || planta.length === 0"
                    selected
                    value="0"
                  >
                    Seleccionar...
                  </option>
                  <option *ngFor="let i of planta" [value]="i.plantId">
                    {{ i.name }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>

              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Tipo de camión <span>*</span></label
                >
                <select
                  class="form-select"
                  id="tipo_camion_id"
                  required
                  formControlName="volumesDistribution"
                  (change)="onChangeCamion()"
                >
                  <option selected value="0">Seleccionar...</option>
                  <option
                    *ngFor="let i of volumen"
                    [value]="i.volumesDistribution"
                    [selected]="i.volumesDistribution === IdCamion"
                  >
                    {{ i.volume }} m<sup>3</sup> - {{ i.volumesDistribution }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-30">
        <div class="card">
          <div class="card-body">
            <form class="row g-3" id="FormRight">
              <div class="col-md-4">
                <label for="validationDefault01" class="form-label"
                  >Numero de OC</label
                >
                <input
                  formControlName="purchaseOrder"
                  type="text"
                  class="form-control"
                  id="numerooc"
                  required
                  placeholder="123901576"
                  autocomplete="off"
                  (input)="autoFillDate($event, 'purchaseOrderDate')"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault02" class="form-label"
                  >Fecha de OC</label
                >
                <input
                  type="date"
                  id="fecha_oc"
                  class="form-control"
                  placeholder="9-12-2023"
                  formControlName="purchaseOrderDate"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault03" class="form-label"
                  >Ref. Externa de OC</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="refoc"
                  required
                  formControlName="purchaseOrderExternalReference"
                  placeholder="123901576"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault01" class="form-label"
                  >Numero de HES</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="numerohes"
                  required
                  placeholder="123901576"
                  autocomplete="off"
                  formControlName="HES"
                  (input)="autoFillDate($event, 'HESDate')"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault02" class="form-label"
                  >Fecha de HES</label
                >
                <input
                  type="date"
                  id="fecha_hes"
                  class="form-control"
                  formControlName="HESDate"
                  placeholder="9-12-2023"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault03" class="form-label"
                  >Ref. Externa de HES</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="refhes"
                  required
                  formControlName="HESExternalReference"
                  placeholder="123901576"
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div *ngIf="validaSelecTipoCamion" class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between">
            <div class="card-title">
              Materiales <span style="color: red">*</span>
            </div>
            <p class="capitalize-first">
              Debe escoger la capacidad del camión entre los materiales
            </p>
          </div>
          <div class="card-body">
            <table class="table table-striped table-responsive">
              <thead>
                <tr>
                  <th scope="col">NEW Material</th>
                  <th
                    *ngFor="let i of volumenData"
                    id="i"
                    scope="col"
                    class="chk{{ i }}"
                  >
                    {{ i }}m<sup>3</sup>
                  </th>
                  <th scope="col" class="text-center">
                    Total por Material (m<sup>3</sup>)
                  </th>
                </tr>
              </thead>
              <tbody
                *ngIf="form.controls['materials']"
                formGroupName="materials"
              >
                <tr
                  *ngFor="let material of materialesDisponibles; let i = index"
                >
                  <td>
                    {{ material.shortDescription }}
                    {{ material.materialId }}
                  </td>

                  <td *ngFor="let volume of volumenData; let j = index">
                    <div class="form-check form-switch form-switch-sm">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="{{ j + volume }}"
                        [formControlName]="(j + volume).toString()"
                        value="{{ material.materialId }}"
                        [checked]="isChecked(j, volume, material.materialId)"
                        (click)="
                          toggleRadio(
                            (j + volume).toString(),
                            material.materialId
                          )
                        "
                      />
                    </div>
                  </td>
                  <td class="text-center">
                    {{ getSuma(material.materialId) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between">
            <div class="card-title">Observación</div>
          </div>
          <div class="card-body">
            <label for="exampleFormControlTextarea1" class="form-label"
              >Escriba su observación</label
            >
            <textarea
              class="form-control"
              id="observacion"
              rows="8"
              formControlName="comment"
              style="resize: none"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-40 mb-40 botones">
        <button class="btn btn-secondary" (click)="limpiarFormulario()">
          Limpiar
        </button>
        <button class="btn btn-primary" (click)="agregarSolicitud()">
          Agregar Solicitud
        </button>
      </div>
    </div>
  </div>
</section>

<div *ngIf="loading" class="overlay">
  <div class="loader"></div>
</div>

<script src="../../assets/js/script.js"></script>
