import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import Swal from 'sweetalert2';
import { UserProfileModel } from '../models/userProfile.model';
import { DebtorModel } from '../models/debtors.model';
import { PetitionerService } from '../services/petitioner/petitioner.service';
import { PetitionerModel } from '../models/Petitioner.model';
import { ShoppingCartService } from '../services/shoppingCart/shopping-cart.service';
import { ShoppingCartModel } from '../models/shopppingCart.model';
import { VolumeModel } from '../models/volume.model';
import { FormControl, FormGroup } from '@angular/forms';
import { PlantModel } from '../models/plant.model';
import { DeliveryWindowService } from '../services/delivery-windows/delivery-window.service';
import { PlantsService } from '../services/plants/plants.service';
import { MaterialsService } from '../services/materials/materials.service';
import { DeliveryWindow } from '../models/deliveryWindow.model';
import { MaterialModel } from '../models/material.model';

@Component({
  selector: 'app-edit-solicitud',
  templateUrl: './edit-solicitud.component.html',
  styleUrl: './edit-solicitud.component.css',
})
export class EditSolicitudComponent implements OnInit, OnDestroy {
  usuario: UserModel;
  userProfile: UserProfileModel;
  requestId: any;
  endpoint: string = environment.apiUrl;
  nuevaRespuesta: any;
  solicitudSeleccionada!: ShoppingCartModel;
  petitioners: string[] = [];
  debtorId: string | undefined;
  solicitante: PetitionerModel[] = [];
  minDate: any;
  maxDate: any;
  ventana_entrega: DeliveryWindow[] = [];
  planta: { plantId: string; name: string }[] = [];
  materials: MaterialModel[] = [];
  material: { materialId: string; name: string }[] = [];
  volumen: VolumeModel[] = [];
  volumenData: [] = [];
  validaSelectTipoCation: boolean = false;
  //Para controlar los valores de los inputs → Check
  sumaTotal: number = 0;
  lastCheckedCheckbox: HTMLInputElement | null = null;
  mappedMaterials: {
    [key: string]: {
      materialId: string;
      description: string;
      shortDescription: string;
    };
  } = {};
  checkboxValues: { [key: number]: string[] } = {};
  sumaCheck2: { [key: number]: number } = {};
  datosPedidos: any;
  orderId: any;
  idPlanta: any;
  idCamion: any;
  rangoHorario: any[] = [];
  loading: boolean = false;
  debtorList: DebtorModel[] = [];
  bandera: any;
  editFrom = new FormGroup({
    debtor: new FormControl(''),
    petitioner: new FormControl(''),
  });

  private userSubscription: Subscription = new Subscription();
  private plantsSubscription: Subscription = new Subscription();
  private deliveryWindowsSubscription: Subscription = new Subscription();
  private materialsSubscription: Subscription = new Subscription();
  allPlants: { [index: string]: PlantModel } = {};
  selectedPetitioner: PetitionerModel | undefined;
  assignedVolumeCount: { [key: string]: number } = {};
  materialIds: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private _userService: UserService,
    private httpClient: HttpClient,
    private router: Router,
    private petitionerService: PetitionerService,
    private shoppingCartService: ShoppingCartService,
    private materialsService: MaterialsService,
    private plantsService: PlantsService,
    private deliveryWindowsService: DeliveryWindowService
  ) {
    this.loading = true;
    this.usuario = this._userService.getUser();
    this.userProfile = this._userService.getUserProfile();
    this.materials = this.materialsService.getMaterialsList();
    this.allPlants = this.plantsService.getPlantsObject();
    this.ventana_entrega = this.deliveryWindowsService.getDeliveryWindows();
  }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe(async (params: Params): Promise<void> => {
      this.requestId = params['id'];
    });
    this.userSubscription = this._userService.userChanged.subscribe(
      (user: UserModel): void => {
        if (user.status === 'online') {
          this.usuario = user;
          this.userProfile = this._userService.getUserProfile();
          this.debtorList = this.userProfile.debtors;
          this.llamarNuevoEndpoint(this.userProfile.userId);
        }
      }
    );
    this.suscriptionsMasters();
    this.minDate = new Date().toISOString().split('T')[0];
    const today: Date = new Date();
    const tomorrow: Date = new Date(today);
    tomorrow.setDate(today.getDate() + 30);
    this.maxDate = tomorrow.toISOString().split('T')[0];
  }
  mostrarAlerta(
    texto: string,
    icon: any,
    redireccionar: boolean = false,
    ruta?: string
  ) {
    Swal.fire({
      title: texto,
      icon: icon,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#002948',
    }).then((result) => {
      if (result.isConfirmed && redireccionar) {
        // El usuario ha hecho clic en el botón OK y se debe redirigir
        // Verificar si se proporcionó una ruta válida
        if (ruta) {
          this.router.navigate([ruta]);
        } else {
          console.error(
            'No se proporcionó una ruta válida para la redirección.'
          );
        }
      }
    });
  }
  //paso 1 flujo llenar desde el carro traigo data desde el carro
  // y selecciono campos a partir desde esa solicitud
  llamarNuevoEndpoint(createdBy: string): void {
    this.shoppingCartService.getShoppingCart(createdBy).subscribe({
      next: (shoppingCart) => {
        // Filtrar la solicitud correspondiente al ID requerido
        let selected = shoppingCart.find(
          (shoppingItem) =>
            shoppingItem.shoppingCartRequestsId === this.requestId
        );
        if (selected) {
          this.solicitudSeleccionada = selected;
          this.editFrom
            .get('debtor')
            ?.setValue(this.solicitudSeleccionada.debtor);
          let petitioners = this.userProfile.debtors.find(
            (x) => x.debtor === this.solicitudSeleccionada.debtor
          )?.petitioners;
          if (petitioners) {
            this.petitioners = petitioners;
            this.procesarPetitioners();
          }
          this.idCamion =
            this.solicitudSeleccionada.volumes[0].volumesDistribution;
        }
        const purchaseOrderElement = document.getElementById(
          'numerooc'
        ) as HTMLInputElement;
        purchaseOrderElement.value = this.solicitudSeleccionada.purchaseOrder;
        const dateOrderElement = document.getElementById(
          'fecha_oc'
        ) as HTMLInputElement;
        dateOrderElement.value = this.solicitudSeleccionada.purchaseOrderDate;
        const referenceOrderElement = document.getElementById(
          'refoc'
        ) as HTMLInputElement;
        referenceOrderElement.value =
          this.solicitudSeleccionada.purchaseOrderExternalReference;
        const purchasehesElement = document.getElementById(
          'numerohes'
        ) as HTMLInputElement;
        purchasehesElement.value = this.solicitudSeleccionada.HES;
        const datehesElement = document.getElementById(
          'fecha_hes'
        ) as HTMLInputElement;
        datehesElement.value = this.solicitudSeleccionada.HESDate;
        const referencehesElement = document.getElementById(
          'refhes'
        ) as HTMLInputElement;
        referencehesElement.value =
          this.solicitudSeleccionada.HESExternalReference;

        const selectElement2 = document.getElementById(
          'observacion'
        ) as HTMLTextAreaElement;
        selectElement2.value = this.solicitudSeleccionada.comment;
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
        this.mostrarAlerta(
          'Ha ocurrido un error al obtener los pedidos',
          'error'
        );
      },
    });
  }
  onChangeCamion2(IdCamion: any): void {
    if (IdCamion === '0') {
      this.validaSelectTipoCation = false;
      return;
    }
    this.sumaTotal = 0;
    this.validaSelectTipoCation = true;
    let volumen = IdCamion;
    volumen = volumen.split('|');
    this.volumenData = volumen;
    this.resetValoresCheck();
    // Convertir los valores de string a números y sumarlos
    this.sumaTotal = volumen.reduce(
      (total: number, valor: string) => total + parseFloat(valor),
      0
    );
    this.setSuma();
  }

  setSuma() {
    this.sumaCheck2 = {};
    this.materialIds.forEach((element) => {
      this.sumaCheck2[element] = 0;
    });
    if (this.materialIds.length === 1) {
      this.materialIds.forEach((element) => {
        this.sumaCheck2[element] = this.sumaTotal;
      });
    } else {
      this.solicitudSeleccionada.materials.forEach((mat) => {
        const materialId = Number(mat.materialId);
        const volume = Number(mat.volume);
        // Inicializar a 0 si no existe aún el valor para el materialId
        this.sumaCheck2[materialId] = this.sumaCheck2[materialId] || 0;
        this.sumaCheck2[materialId] += volume;
      });
    }
  }

  onCheckboxChange(event: any, value: string, material: any): void {
    const materialkey = parseInt(material, 10);
    if (!this.checkboxValues.hasOwnProperty(materialkey)) {
      this.checkboxValues[materialkey] = [];
    }
    if (event.target.checked) {
      if (
        this.calculateGlobalSum() + parseFloat(value) <=
        this.calculateTruckSum()
      ) {
        this.disableCheckboxesWithSameVolume(materialkey, value);
        this.sumaCheck2[materialkey] += Number(value);
      } else {
        // No permitir agregar el nuevo valor si excede la capacidad del camión
        event.target.checked = false;
        this.mostrarAlerta(
          'La solicitud no puede ser mayor que la capacidad del camión',
          'error'
        );
      }
    } else {
      console.log('Enable all');
      this.sumaCheck2[materialkey] -= Number(value);
      this.enableAllCheckboxes(materialkey, value);
    }
    this.lastCheckedCheckbox = event.target.checked ? event.target : null;
    // Validar la suma total con la capacidad del camión
    this.validateSumWithTruck();
    this.loading = false;
  }

  enableAllCheckboxes(materialkey: number, volume: string): void {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[type="checkbox"].material-checkbox`
    );
    console.log(checkboxes);
    checkboxes.forEach((checkbox) => {
      if (checkbox.value === volume) {
        console.log('WTF');
        checkbox.disabled = false;
      }
    });
  }
  disableCheckboxesWithSameVolume(materialkey: number, volume: string): void {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[type="checkbox"].material-checkbox`
    );
    checkboxes.forEach((checkbox) => {
      if (
        checkbox.value === volume &&
        materialkey !== parseInt(checkbox.id, 10)
      ) {
        checkbox.disabled = true;
      }
    });
  }
  calculateMaterialSum(values: string[]): number {
    return values.reduce((total, valor) => total + parseFloat(valor), 0);
  }

  calculateGlobalSum(): number {
    // Obtener todos los valores marcados de todos los materiales
    const allCheckboxValues = Object.values(this.checkboxValues).flat();
    // Sumar los valores convertidos a números
    return allCheckboxValues.reduce(
      (total, valor) => total + parseFloat(valor),
      0
    );
  }
  validateSumWithTruck(): void {
    // Obtener la suma total global
    const sumaTotalGlobal = this.calculateGlobalSum();
    // Obtener la suma del camión
    const sumaCamion = this.calculateTruckSum();
    // Comparar las sumas y realizar la validación
    if (sumaTotalGlobal > sumaCamion) {
      if (this.lastCheckedCheckbox) {
        const lastCheckedCheckboxValue = parseFloat(
          this.lastCheckedCheckbox.value
        );
        this.lastCheckedCheckbox.checked = false;
        this.lastCheckedCheckbox = null; // Restablecer la variable
        this.calculateGlobalRes(lastCheckedCheckboxValue);
      }
      this.mostrarAlerta(
        'La solicitud no puede ser mayor que la capacidad del camión',
        'error'
      );
    } else if (sumaTotalGlobal === sumaCamion) {
      // Realizar acciones en caso de que las sumas sean iguales
    }
  }
  calculateTruckSum(): number {
    const volumenTotal = this.volumenData.reduce(
      (total, valor) => total + parseFloat(valor),
      0
    );
    return volumenTotal;
  }
  autoFillDate(event: any, id: any): void {
    const numerohes = event.target.value;
    const fecha_hes = document.getElementById(id) as HTMLInputElement;
    if (numerohes) {
      fecha_hes.valueAsDate = new Date(); // Establece la fecha actual
    } else {
      fecha_hes.value = ''; // Borra la fecha
    }
  }
  calculateGlobalRes(lastCheckedCheckboxValue: number): void {
    for (const materialKey in this.checkboxValues) {
      if (this.checkboxValues.hasOwnProperty(materialKey)) {
        const checkboxValuesMaterial = this.checkboxValues[materialKey];
        if (
          checkboxValuesMaterial.includes(lastCheckedCheckboxValue.toString())
        ) {
          const index = checkboxValuesMaterial.indexOf(
            lastCheckedCheckboxValue.toString()
          );
          checkboxValuesMaterial.splice(index, 1);
          const numericMaterialKey = parseInt(materialKey, 10); // Convertir a número
          this.updateMaterialSum(
            numericMaterialKey,
            this.calculateMaterialSum(checkboxValuesMaterial)
          );
        }
      }
    }

    // Actualizar la suma total global después de los cambios
    this.validateSumWithTruck();
  }
  onChangeCamion(event: any): void {
    //reset all in table materials
    this.resetValoresCheck();
    this.solicitudSeleccionada.materials = [];
    this.materialIds.forEach((element) => {
      this.sumaCheck2[element] = 0;
    });

    let volumen = event;
    if (volumen !== '0') {
      volumen = event.target.value;
    }
    volumen = volumen.split('|');
    this.volumenData = volumen;
    this.resetValoresCheck();
    // Convertir los valores de string a números y sumarlos
    this.sumaTotal = volumen.reduce(
      (total: number, valor: string) => total + parseFloat(valor),
      0
    );
    if (event.target.value === '0') {
      this.resetValoresCheck();
      this.validaSelectTipoCation = false;
    } else {
      this.resetValoresCheck();
      this.validaSelectTipoCation = true;
    }
    const soloUnMaterial = Object.keys(this.mappedMaterials).length === 1;
    if (soloUnMaterial) {
      this.setSuma();
      // Obtener el único material
      const materialId = Object.keys(this.mappedMaterials)[0];
      // Iterar sobre los checkboxes y marcarlos
      this.volumenData.forEach((i) => {
        const checkboxId = `${materialId}_${i}`;
        const checkboxElements = document.querySelectorAll(
          `.prendido-${checkboxId}`
        );

        checkboxElements.forEach((checkboxElement) => {
          if (
            checkboxElement instanceof HTMLInputElement &&
            !checkboxElement.checked
          ) {
            checkboxElement.checked = true;
            // Verifica que los checkboxes se estén marcando correctamente

            this.sumaTotal = volumen.reduce(
              (total: number, valor: string) => total + parseFloat(valor),
              0
            );
            const materialId1 = parseInt(
              Object.keys(this.mappedMaterials)[0],
              10
            );
            this.updateMaterialSum(materialId1, this.sumaTotal.valueOf());
            this.bandera = 1;
          }
        });
      });
      // Esperar 100 milisegundos antes de ejecutar el código
    } else {
      if (this.bandera == 1) {
        this.sumaCheck2[1] = 0;
      }
    }
  }

  //paso 2 Flujo rellenar desde el carro.
  //traer los petitiones y selecionar el que viene el la solicitud del carro.
  procesarPetitioners(): void {
    this.petitionerService.getPetitioners(this.petitioners).subscribe({
      next: (petitioners) => {
        this.solicitante = petitioners;
        this.editFrom
          .get('petitioner')
          ?.setValue(this.solicitudSeleccionada.petitionerId);
        this.fetchPetitioner(this.solicitudSeleccionada.petitionerId);
      },
      error: (error) => {
        this.mostrarAlerta('Error al procesar algún petitioners', 'error');
      },
    });
  }

  selectDate(event: any): void {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
    this.obtenerRangoHorario(currentTime, selectedDate, currentDate);
  }

  obtenerRangoHorario(
    currentTime: number,
    selectedDate: Date,
    currentDate: Date
  ): DeliveryWindow[] {
    const selectedDateISOString = selectedDate.toISOString().split('T')[0];
    const currentDateISOString = currentDate.toISOString().split('T')[0];
    const esMismaFecha = selectedDateISOString === currentDateISOString;
    const currentDatePlusOne = new Date(currentDate);
    currentDatePlusOne.setDate(currentDatePlusOne.getDate() + 1);
    const currentDatePlusOneISOString = currentDatePlusOne
      .toISOString()
      .split('T')[0];
    const tomorrow = selectedDateISOString === currentDatePlusOneISOString;
    if (esMismaFecha) {
      this.rangoHorario = this.ventana_entrega.filter((rango) => {
        return rango.windowId === '004';
      });
    } else if (tomorrow) {
      let horaActual = Math.floor(currentTime / 60);
      if (horaActual < 14) {
        this.rangoHorario = this.ventana_entrega;
      } else if (horaActual >= 17) {
        this.rangoHorario = this.ventana_entrega.filter((rango) => {
          return rango.windowId === '004';
        });
      } else {
        this.rangoHorario = this.ventana_entrega.filter((rango) => {
          //aqui habil, pm y cualquiera
          return (
            rango.windowId === '005' ||
            rango.windowId === '002' ||
            rango.windowId === '004'
          );
        });
      }
    } else {
      // Si no es la misma fecha, asignar todos los rangos disponibles
      this.rangoHorario = this.ventana_entrega;
    }
    return this.rangoHorario;
  }

  onChangePlanta(event: any, html?: boolean): void {
    let plantId = event;
    if (html) {
      plantId = event.target.value;
      this.idCamion = '0';
    }
    let plant = this.allPlants[plantId];
    this.volumen = plant.volumes;
    this.loading = false;
    this.validaSelectTipoCation = false;
    if (plantId === '0') {
      this.volumen = [];
      this.validaSelectTipoCation = false;
      this.resetValoresCheck();
      this.onChangeCamion('0');
    } else {
      this.validaSelectTipoCation = false;
      const uniqueMaterialIdsSet = new Set(
        this.selectedPetitioner?.materials
          .filter((material) => plantId === material.plantId)
          .map((material) => material.materialId)
      );
      this.materialIds = Array.from(uniqueMaterialIdsSet);
      this.volumen = plant.volumes;
      this.validaSelectTipoCation = false;
      this.handleMaterialsIds(this.materialIds);
      this.onChangeCamion2(this.idCamion);
    }
  }
  /****** Funciones de pedido */
  resetValoresCheck(): void {
    for (const materialkey in this.checkboxValues) {
      if (this.checkboxValues.hasOwnProperty(materialkey)) {
        const numericMaterialKey = parseInt(materialkey, 10); // Convertir a número
        this.checkboxValues[materialkey] = []; // Limpiar los valores del material
        this.updateMaterialSum(numericMaterialKey, 0); // Utilizar numericMaterialKey en lugar de materialkey
      }
    }
    this.lastCheckedCheckbox = null; // Restablecer la variable
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  updateMaterialSum(materialkey: number, newValue: number): void {
    this.sumaCheck2[materialkey] = newValue;
  }
  fetchPetitioner(value: string): void {
    if (value === '') return;
    let plantsIds: any[] = [];
    this.selectedPetitioner = this.solicitante.find(
      (solicitante) => solicitante.petitionerId === value
    );
    const uniquePlantIdsSet = new Set(
      this.selectedPetitioner?.materials.map((material) => material.plantId)
    );
    plantsIds = Array.from(uniquePlantIdsSet);
    this.handlePlantsIds(plantsIds);
    this.loading = false;
  }

  handlePlantsIds(plantsIds: string[]): void {
    this.planta = [];
    for (const id of plantsIds) {
      const planta = this.allPlants[id]; // Acceso directo al objeto
      if (planta) {
        this.planta.push(planta); // Solo agrega si existe
      }
    }
    this.idPlanta = this.solicitudSeleccionada.plantId;
    if (this.planta.some((x) => x.plantId === this.idPlanta)) {
      this.onChangePlanta(this.idPlanta);
    } else if (this.planta.length === 1) {
      this.idPlanta = this.planta[0].plantId;
      this.onChangePlanta(this.idPlanta);
    }
  }
  checkIsMasive(material: string): boolean {
    return this.solicitudSeleccionada.materials.some((element) => {
      if (parseInt(element.volume) == this.calculateTruckSum()) {
        if (material == element.materialId) {
          console.log('HERE?');
          console.log(material, element.materialId);
          return true;
        }
      }
      return false;
    });
  }
  checkDinamic(material: string, volume: string) {
    //la lista de la planta
    if (this.materialIds.length === 1) {
      return true;
    }
    if (this.checkIsMasive(material)) {
      this.disableCheckboxesWithSameVolume(parseInt(material), volume);
      return true;
    }
    if (
      this.solicitudSeleccionada.materials.some(
        (x) => x.materialId === material && volume === x.volume
      )
    ) {
      this.disableCheckboxesWithSameVolume(parseInt(material), volume);
      console.log('esta es la mierda');
      return true;
    } else {
      return false;
    }
  }
  handleMaterialsIds(materialIds: string[]): void {
    // Filtrar los materiales que coincidan con los IDs proporcionados
    const filteredMaterials = this.materials.filter((material) =>
      materialIds.includes(material.materialId)
    );
    // Mapear los materiales filtrados al objeto mappedMaterials
    this.mappedMaterials = filteredMaterials.reduce((result: any, material) => {
      result[material.materialId] = {
        ...material,
      };
      return result;
    }, {});
  }

  editarSolicitud(): void {
    const debtorValue = this.editFrom.get('debtor')?.value;
    const solicitanteValue = this.editFrom.get('petitioner')?.value;
    const fechaEntregaValue = (<HTMLInputElement>(
      document.getElementById('fecha_entrega')
    )).value;
    const ventanaEntregaValue = (<HTMLSelectElement>(
      document.getElementById('ventana_entrega')
    )).value;
    const plantaValue = (<HTMLSelectElement>document.getElementById('plantaId'))
      .value;
    const tipoCamionValue = (<HTMLSelectElement>(
      document.getElementById('tipo_camion_id')
    )).value;
    const numerooc = (<HTMLSelectElement>document.getElementById('numerooc'))
      .value;
    const fechaoc = (<HTMLSelectElement>document.getElementById('fecha_oc'))
      .value;
    const refoc = (<HTMLSelectElement>document.getElementById('refoc')).value;
    const numerohes = (<HTMLSelectElement>document.getElementById('numerohes'))
      .value;
    const fechahes = (<HTMLSelectElement>document.getElementById('fecha_hes'))
      .value;
    const refhes = (<HTMLSelectElement>document.getElementById('refhes')).value;
    const obs = (<HTMLSelectElement>document.getElementById('observacion'))
      .value;
    if (!solicitanteValue || solicitanteValue === '') {
      this.mostrarAlerta('Por favor, ingrese un solicitante', 'info');
      this.loading = false;
    } else if (!fechaEntregaValue) {
      this.mostrarAlerta('Por favor, selecciona una Fecha de Entrega.', 'info');
      this.loading = false;
    } else if (!ventanaEntregaValue) {
      this.mostrarAlerta(
        'Por favor, selecciona una Ventana de Entrega.',
        'info'
      );
      this.loading = false;
    } else if (!plantaValue || plantaValue === '0') {
      this.mostrarAlerta('Por favor, selecciona una Planta.', 'info');
      this.loading = false;
    } else if (!tipoCamionValue || tipoCamionValue === '0') {
      this.mostrarAlerta('Por favor, selecciona un Tipo de Camión.', 'info');
      this.loading = false;
    } else if (!this.recogerCheckboxesPrendidos2()) {
      return;
    } else {
      const checkboxesPrendidos = this.recogerCheckboxesPrendidos();
      let materialsArray = checkboxesPrendidos
        .map((checkbox) => {
          return checkbox.valoresPrendidos.map((valor) => {
            let materiaid = checkbox.materialType.split('_');
            return {
              materialId: materiaid[0],
              volume: valor,
              unitOfMeasure: 'M3',
            };
          });
        })
        .flat();
      let numeros = tipoCamionValue.split('|').map(Number);
      let postData = {
        createdBy: this.userProfile.userId,
        user: this.userProfile.userId,
        debtor: debtorValue,
        shoppingCartRequestsId: this.requestId,
        petitionerId: solicitanteValue,
        requestedDeliveryTimeWindow: ventanaEntregaValue,
        requestedDeliveryDate: fechaEntregaValue,
        purchaseOrder: numerooc,
        purchaseOrderDate: fechaoc,
        purchaseOrderExternalReference: refoc,
        HES: numerohes,
        HESDate: fechahes,
        HESExternalReference: refhes,
        comment: obs,
        plantId: plantaValue,
        volume: this.sumaTotal.toString(),
        unitOfMeasure: 'M3',
        compartmentsNumber: numeros.length.toString(),
        volumesDistribution: tipoCamionValue,
        materialId: materialsArray[0].materialId,
        volumes: [
          {
            volume: this.sumaTotal.toString(),
            unitOfMeasure: 'M3',
            compartmentsNumber: tipoCamionValue.split('|').length.toString(),
            volumesDistribution: tipoCamionValue,
          },
        ],
        materials: materialsArray,
      };
      const url = `${this.endpoint}shopping-cart-request`;
      // Realiza la solicitud POST
      this.httpClient.put(url, postData).subscribe(
        (response) => {
          this.loading = false;
          this.mostrarAlerta('Solicitud editada correctamente', 'success');
          this.router.navigate(['/solicitud'], { state: { postData } });
        },
        (error) => {
          this.mostrarAlerta(
            'Error: ' + error.error.message,
            'error',
            true,
            '/home'
          );
        }
      );
    }
  }

  recogerCheckboxesPrendidos2(): boolean {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"].material-checkbox:checked'
    );
    if (checkboxes.length === 0) {
      this.mostrarAlerta(
        'Por favor, selecciona al menos un valor para alguna columna.',
        'info'
      );
      return false;
    }
    // Calcular la suma de los valores de los checkboxes marcados
    const sumaCheckboxes = Array.from(checkboxes).reduce(
      (suma, checkbox) => suma + parseFloat(checkbox.value),
      0
    );
    // Obtener la suma del camión
    const sumaCamion = this.calculateTruckSum();
    // Comparar las sumas y realizar la validación
    if (sumaCheckboxes !== sumaCamion) {
      this.mostrarAlerta(
        'La suma de los compartimiento no coincide con la capacidad del camión.',
        'error'
      );
      return false;
    }
    return true;
  }

  recogerCheckboxesPrendidos(): {
    materialType: string;
    valoresPrendidos: string[];
  }[] {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[type="checkbox"].material-checkbox:checked`
    );
    const result: { materialType: string; valoresPrendidos: string[] }[] = [];
    checkboxes.forEach((checkbox) => {
      const materialType = checkbox.getAttribute('id') || '';
      const valor = checkbox.value;
      // Buscar si ya se agregó este material en el resultado
      const materialExistente = result.find(
        (item) => item.materialType === materialType
      );
      if (materialExistente) {
        materialExistente.valoresPrendidos.push(valor);
      } else {
        // Si no existe, agregar un nuevo objeto con el material y su valor
        result.push({ materialType, valoresPrendidos: [valor] });
      }
    });
    return result;
  }

  cancelarSolicitud() {
    this.router.navigate(['/solicitud']);
  }
  onChangedebtor(event: any) {
    // Obtener el ID del deudor seleccionado
    const selectedDebtorId = event.target.value;
    // Encontrar el deudor seleccionado en la lista de deudores
    const selectedDebtor = this.debtorList.find(
      (debtor) => debtor.debtor === selectedDebtorId
    );
    // Verificar si se encontró el deudor seleccionado
    if (selectedDebtor) {
      this.petitioners = selectedDebtor.petitioners;
      this.editFrom.get('petitioner')?.setValue('');
      this.petitionerService.getPetitioners(this.petitioners).subscribe({
        next: (petitioners) => {
          this.solicitante = petitioners;
        },
      });
    }
  }
  changePetitioner() {
    let petitionerId = this.editFrom.get('petitioner')?.value;
    if (petitionerId) {
      this.fetchPetitioner(petitionerId);
      this.validaSelectTipoCation = false;
      this.resetValoresCheck();
    } else {
      return;
    }
  }

  suscriptionsMasters() {
    this.plantsSubscription = this.plantsService.plantsObjectChanged.subscribe(
      (plantsIndex) => {
        this.allPlants = plantsIndex;
      }
    );
    this.deliveryWindowsSubscription =
      this.deliveryWindowsService.deliveryWindowsChanged.subscribe(
        (deliveryWindows) => {
          this.ventana_entrega = deliveryWindows;
        }
      );
    this.materialsSubscription =
      this.materialsService.materialsChanged.subscribe(
        (materialsObject): void => {
          this.materials = materialsObject;
        }
      );
  }
  ngOnDestroy(): void {
    this.plantsSubscription.unsubscribe();
    this.deliveryWindowsSubscription.unsubscribe();
    this.materialsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    console.log('edit-solicitud - subscripciones deshechas.');
  }
}
