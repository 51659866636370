<header>
  <app-header></app-header>
</header>
<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between">
            <div class="card-title" *ngIf="solicitudesMensaje">
              {{ solicitudesMensaje }}
            </div>
          </div>
          <div
            class="col-md-12 mt-40 mb-40 botones"
            *ngIf="
              !loading &&
              nuevaRespuesta?.data &&
              nuevaRespuesta.data &&
              nuevaRespuesta.data.length > 0
            "
          >
            <div class="d-flex justify-content-between align-items-start">
              <div>
                <button class="btn btn-danger me-2" (click)="alldelete()">
                  Eliminar Solicitudes Seleccionadas
                </button>
              </div>
              <div class="d-flex flex-column align-items-start">
                <button
                  class="btn btn-primary"
                  (click)="enviarsolicitud2()"
                  [disabled]="!selectedHasErrors()"
                >
                  Enviar Solicitudes
                </button>
                <label class="mt-2">
                  Seleccionar todas &nbsp;
                  <input
                    type="checkbox"
                    style="transform: scale(1.7); margin-right: 5px"
                    [checked]="selectAll"
                    (change)="toggleSelectAll()"
                  />
                </label>
              </div>
            </div>
          </div>

          <div
            class="text-center"
            *ngIf="
              !loading &&
              (!nuevaRespuesta ||
                !nuevaRespuesta.data ||
                nuevaRespuesta.data.length === 0)
            "
          >
            No hay solicitudes en el carrito.
          </div>

          <div class="card-body">
            <table
              class="table table-responsive"
              *ngIf="
                !loading &&
                nuevaRespuesta?.data &&
                nuevaRespuesta.data &&
                nuevaRespuesta.data.length > 0
              "
            >
              <caption></caption>
              <thead>
                <tr style="background-color: #e9ecef">
                  <th class="text-center" scope="col">N°</th>
                  <th class="text-center" scope="col">Solicitante</th>
                  <th class="text-center" scope="col">Fecha de Entrega</th>
                  <th class="text-center" scope="col">Ventana de Entrega</th>
                  <th class="text-center" scope="col">
                    Volumen Total (m<sup>3</sup>)
                  </th>
                  <th class="text-center" scope="col">Materiales</th>
                  <th class="text-center" scope="col">Número de OC</th>
                  <th class="text-center" scope="col">Número de HES</th>
                  <th class="text-center" scope="col">Opciones</th>
                  <th class="text-center" scope="col">
                    Seleccionados: {{ countSelection }}
                  </th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr
                  *ngFor="
                    let solicitud of nuevaRespuesta?.data || [];
                    let i = index
                  "
                  [ngClass]="{ 'table-danger': solicitud.hasError }"
                >
                  <td class="text-center">
                    {{ i + 1 }}
                  </td>
                  <td class="text-center capitalize-first">
                    {{ solicitud?.petitionerId }} -
                    {{
                      petitionerNameBuscar[solicitud?.shoppingCartRequestsId]
                    }}
                  </td>
                  <td class="text-center">
                    {{ solicitud?.requestedDeliveryDate | date : "dd-MM-yyyy" }}
                  </td>
                  <td class="text-center">
                    {{ horasInicioOrder[solicitud?.shoppingCartRequestsId] }} -
                    {{ horasFinOrder[solicitud?.shoppingCartRequestsId] }}
                  </td>
                  <td class="text-center">
                    {{ volumenTotal[solicitud?.shoppingCartRequestsId] }}
                  </td>
                  <td class="text-center">
                    <ng-container
                      *ngFor="
                        let materialId of getMaterialIds(
                          solicitud?.shoppingCartRequestsId
                        )
                      "
                    >
                      <div class="capitalize-first">
                        {{
                          volumenPorMaterialPorSolicitud[
                            solicitud?.shoppingCartRequestsId
                          ][materialId]?.description || "Nombre no encontrado"
                        }}
                        - Volumen:
                        {{
                          volumenPorMaterialPorSolicitud[
                            solicitud?.shoppingCartRequestsId
                          ][materialId]?.volume || 0
                        }}
                      </div>
                    </ng-container>
                  </td>
                  <td class="text-center">{{ solicitud?.purchaseOrder }}</td>
                  <td class="text-center">{{ solicitud?.HES }}</td>
                  <td class="text-center" style="width: 150px">
                    <div class="d-flex justify-content-start">
                      <img
                        *ngIf="solicitud.hasError"
                        src="../../assets/img/error2.png"
                        alt="Error"
                        width="30px"
                        height="30px"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="{{ solicitud?.errorMessage }}"
                        style="cursor: pointer; margin-right: 5px"
                        (click)="
                          openModal(
                            solicitud?.errorMessage,
                            solicitud?.petitionerId,
                            petitionerNameBuscar[
                              solicitud?.shoppingCartRequestsId
                            ],
                            'Error'
                          )
                        "
                        alt="error"
                        (keydown)="onKeyDown($event)"
                        (keypress)="onKeyPress($event)"
                        (keyup)="onKeyUp($event)"
                      />
                      <img
                        *ngIf="!solicitud.hasError"
                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        alt=""
                        width="30px"
                        height="30px"
                        style="margin-right: 5px"
                      />
                      <ng-container *ngIf="solicitud?.comment !== ''">
                        <img
                          src="../../assets/img/comentario2.png"
                          width="30px"
                          height="30px"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="{{ solicitud?.comment }}"
                          style="cursor: pointer; margin-right: 5px"
                          (click)="
                            openModal(
                              solicitud?.comment,
                              solicitud?.petitionerId,
                              petitionerNameBuscar[
                                solicitud?.shoppingCartRequestsId
                              ],
                              'Comentario'
                            )
                          "
                          alt="comentario"
                          (keydown)="onKeyDown($event)"
                          (keypress)="onKeyPress($event)"
                          (keyup)="onKeyUp($event)"
                        />
                      </ng-container>

                      <img
                        *ngIf="solicitud?.comment === ''"
                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        alt=""
                        width="30px"
                        height="30px"
                        style="margin-right: 5px"
                      />
                      <!-- Imagen transparente para mantener el espacio -->
                      <img
                        src="../../assets/img/lapiz.jpg"
                        alt=""
                        width="25px"
                        height="30px"
                        style="margin-right: 4px"
                        style="cursor: pointer"
                        (click)="
                          editarSolicitud(
                            solicitud?.shoppingCartRequestsId,
                            solicitud?.user
                          )
                        "
                        alt="Editar Solicitud"
                        (keydown)="onKeyDown($event)"
                        (keypress)="onKeyPress($event)"
                        (keyup)="onKeyUp($event)"
                      />
                      <img
                        src="../../assets/img/eliminar.jpg"
                        alt=""
                        width="25px"
                        height="30px"
                        (click)="
                          confirmarEliminacion(
                            solicitud?.shoppingCartRequestsId
                          )
                        "
                        style="cursor: pointer"
                        alt="Eliminar Solicitud"
                        (keydown)="onKeyDown($event)"
                        (keypress)="onKeyPress($event)"
                        (keyup)="onKeyUp($event)"
                      />
                    </div>
                  </td>

                  <td class="text-center">
                    <input
                      type="checkbox"
                      style="transform: scale(1.7); margin-right: 5px"
                      [checked]="solicitud.seleccionada"
                      (change)="toggleSeleccion(solicitud)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div *ngIf="loading" class="overlay">
  <div class="loader"></div>
</div>

<div
  #exampleModal
  class="modal fade"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          data-bs-dismiss="modal"
          (click)="closeModal()"
        ></button>
      </div>
      <div class="modal-body">
        {{ modalContent }}
      </div>
      <div class="modal-footer">
        <button
          #myButton
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          (click)="closeModal()"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
<script src="../../assets/js/script.js"></script>
